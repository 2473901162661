<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Attendance-->
    <div class="row">
      <!-- <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.position"
            :options="filterPosition"
          ></b-form-select>
        </div>
      </div> -->
      <!-- <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.area"
            :options="filterArea"
          ></b-form-select>
        </div>
      </div> -->
      <div
        class="col-12 col-md-2 pt-0 pb-0"
        v-if="permission.some((item) => item.name === 'can_view_list_employee')"
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selectCustomer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div>
      <div
        class="col-12 col-md-2 pt-0 pb-0"
        v-if="permission.some((item) => item.name === 'can_view_list_employee')"
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customerBranch"
            :options="filterCustomerBranch"
          ></b-form-select>
        </div>
      </div>
      <div class="col-xxl-3 col-12 col-md-3 pt-0 pb-0">
        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
          <b-input-group>
            <b-form-select
              id="sort-by-select"
              v-model="refSortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Sort by --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="refSortDirectionChange"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button variant="primary" class="w-100" @click="exportPDF"
            >Export Excel</b-button
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <div class="row align-items-center justify-content-between">
            <div class="col-xxl-3 col-12 col-md-3">
              <b-button
                variant="primary gutter-b"
                @click="handleButtonAdd()"
                v-if="
                  permission.some((item) => item.name === 'can_create_employee')
                "
                >Tambah</b-button
              >
            </div>
            <div class="col-xxl-3 col-12 col-md-3">
              <div class="mb-3 font-weight-bold">
                Total anggota/karyawan: {{ this.totalData }}
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="refPerpage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="listTable"
              :fields="columns"
              :per-page="refPerpage"
              :current-page="refCurrentPage"
              :busy="isLoading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
            >
              <template #cell(id)="row">
                <span class="mr-2">
                  <span
                    :title="row.item.id"
                    class="cursor-pointer"
                    v-clipboard:copy="row.item.id"
                    v-clipboard:success="onCopy"
                    >{{ sortChar(row.item.id) }}</span
                  >
                  <span> </span>
                </span>
              </template>
              <template #cell(action)="row">
                <span class="mr-2" @click="handleView(row.item)">
                  <b-button
                    variant="primary"
                    class="py-1 px-2"
                    id="Detail"
                    title="Detail"
                  >
                    <i class="menu-icon flaticon-eye pr-0"></i>
                  </b-button>
                </span>
                <span
                  class="mr-2"
                  @click="handleEdit(row.item)"
                  v-if="
                    permission.some((item) => item.name === 'can_edit_employee')
                  "
                >
                  <b-button
                    variant="success"
                    class="py-1 px-2"
                    id="Edit"
                    title="Edit"
                  >
                    <i class="menu-icon flaticon-edit pr-0"></i>
                  </b-button>
                </span>
                <span @click="handleDelete(row.item)">
                  <b-button
                    variant="danger"
                    class="py-1 px-2"
                    id="Delete"
                    title="Delete"
                  >
                    <i class="menu-icon flaticon-delete pr-0"></i>
                  </b-button>
                </span>
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalData"
                :per-page="refPerpage"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Attendance-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "debounce";
import { mapGetters } from "vuex";
import { sortChar } from "@/utils/helper";
import mixin from "@/mixins/index";

export default {
  name: "ListEmployee",
  mixins: [mixin],
  data() {
    return {
      filterPosition: [
        { value: null, text: "Filter Jabatan" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      filterArea: [
        { value: null, text: "Filter Area Kerja" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      filterGroup: [
        { value: null, text: "Filter Group Customer" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      filterCustomer: [{ value: null, text: "Filter Customer" }],
      filterCustomerBranch: [{ value: null, text: "Filter Customer" }],
      selected: {
        customer: null,
        customerBranch: null,
        area: null,
        position: null,
      },
      isLoading: false,
      // Table
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "id",
          label: "Employee ID",
          align: "left",
          sortBy: "",
        },
        {
          key: "name",
          label: "Nama",
          align: "left",
          sortBy: "name",
          width: "20%",
          sortable: true,
        },
        {
          key: "nip",
          label: "NIP",
          align: "left",
          sortBy: "",
        },
        {
          key: "customer_branch.name",
          label: "Site",
          align: "left",
          width: "",
          sortBy: "",
        },
        {
          key: "role.name",
          label: "Jabatan",
          align: "left",
          width: "",
          sortBy: "",
        },
        {
          key: "action",
          label: "Aksi",
          align: "center",
          width: "10%",
        },
      ],
      sortBy: "",
      sortDesc: false,
      listTable: [],
      totalData: 0,
      filter: null,
      filterOn: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    refPerpage: {
      get() {
        return this.params.limit;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },

    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },

    refSortDirectionChange: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.sortDesc = value;
        if (value) this.params.order = "desc";
        else this.params.order = "asc";
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    selectCustomer: {
      get() {
        return this.selected.customer;
      },
      set(value) {
        this.selected.customer = value;
        if (value === null) {
          this.selected.customerBranch = null;
          this.filterCustomerBranch = [{ value: null, text: "Pilih Site" }];
        } else this.getCustomerBranchList(value);
      },
    },
  },
  async mounted() {
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employee" },
    ]);
  },
  methods: {
    onCopy(e) {
      this.$bvToast.toast(e.text, {
        title: "Successfuly Copy to clipboard",
        variant: "success",
        solid: true,
      });
    },
    sortChar,
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    // page size change
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    handleButtonAdd() {
      this.$router.push({ path: `/employee/create` });
    },
    handleView(item) {
      this.$router.push({ path: `/employee/view/${item.id}` });
    },
    handleEdit(item) {
      this.$router.push({ path: `/employee/edit/${item.id}` });
    },
    handleDelete(item) {
      this.$bvModal
        .msgBoxConfirm(`Apakah anda ingin menghapus data ini.`, {
          title: "Confirm Delete",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirm) => {
          if (confirm) {
            try {
              this.$axios.delete(
                `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee/${item.id}`
              );
              this.$bvToast.toast("Berhasil menghapus data", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              this.getTabel();
            } catch ({ response }) {
              this.$bvToast.toast(
                response.data.message ?? "Terjadi Kesalahan",
                {
                  title: "Error",
                  variant: "danger",
                  solid: true,
                }
              );
            }
          }
        });
    },
    async getTabel() {
      this.isLoading = true;
      try {
        const params = {
          customer_id: this.selected.customer,
          customer_branch_id: this.selected.customerBranch,
          ...this.params,
        };
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employees`, {
            params,
          })
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalData = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async getCustomerBranchList(customer_id) {
      this.isLoading = true;
      if (
        !this.permission.some((item) => item.name === "can_create_customer")
      ) {
        const customerBranch = this.$store.getters.currentUser.customer_branch;
        this.filterCustomerBranch = [{ value: null, text: "Select All" }];
        customerBranch.map((item) => {
          this.filterCustomerBranch.push({
            value: item.id,
            text: item.name,
          });
        });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      } else {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
          )
          .then(({ data }) => {
            this.filterCustomerBranch = [{ value: null, text: "Select All" }];
            data.list.map((item) => {
              this.filterCustomerBranch.push({
                value: item.id,
                text: item.name,
              });
            });
          });
        this.selected.customerBranch = this.filterCustomerBranch[0].value;
      }
      this.isLoading = false;
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    async exportPDF() {
      const params = {
        customer_id: this.selected.customer,
        customer_branch_id: this.selected.customerBranch,
        ...this.params,
      };
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employees/export`,
          {
            params: {
              ...params,
              limit: 100000,
            },
          }
        )
        .then(({ data }) => {
          window.open(data.file);
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  watch: {
    params: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
